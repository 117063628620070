import TextSignup from 'src/components/TextSignup'

// NOTE: this form is used as an embed on resource pages
// The height needs to be set in the CMS
// <iframe style="width: 100%; height: 400px" src="/embedded-text-signup/" />

const EmbeddedTextSignup = () => (
  <TextSignup
    submitText="Subscribe"
    errorText="We hit a problem, but you can text JOIN to 33-55-77."
    successLocation="/text-us-success/"
  />
)

export default EmbeddedTextSignup
